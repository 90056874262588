import gql from 'not-graphql-tag';

export const getAccountsList = gql`
  query getAccountDetailList($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      totalCount
      timestamp
      accounts {
        accountNumber
        accountType
        encryptedAccountNumber
        encryptedPersonId
        description
        isDefault
        isActive
        mainCustomerName
        relationType
        pendingDisconnect {
          isPendingDisconnect
        }
        autoPay {
          isEnrolled
          draftDate
        }
        premiseInfo {
          encryptedPremiseId
          addressLine1
          addressLine2
          houseType
          city
          county
          state
          postal
          country
          saDetails {
            encryptedSAId
            saStatus
            startDate
            endDate
            nickName
          }
        }
        currentCharges {
          amountDue
          dueDate
        }
        serviceConnectivity {
          isDisconnected
          isEligibleForReconnect
          disconnectionDate
        }
      }
    }
  }
`;
