import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import AccountDropdown from '../account-dropdown';
import Backdrop from '../backdrop';
import {
  AccountDetail,
  AccountType,
  PremiseInfo,
  TimeOfDayEnrollmentDetails,
} from '../../__generated__/pge-types';
import { getPgeEnergyTrackerData } from '../account-summary/single-account/queries';
import colors from '../../themes/main-colors';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../hooks/useAuthQuery';
import useGetAccountType from '../../hooks/useGetAccountType';
import DataBrowserWidget from '../OpowerWidgets/components/EnergyUseDatabrowserWidget';
import NextBestActionWidget from '../OpowerWidgets/components/NextBestActionWidget';
import EnergyAnalysisWidget from '../OpowerWidgets/components/EnergyAnalysisWidget';
import DisaggregationWidget from '../OpowerWidgets/components/DisaggregationWidget';
import {
  GET_ACCOUNT_DETAILS,
  getTimeOfDayEnrollmentDetailsQuery,
} from '../my-programs/queries';
import { getActiveEncryptedSaId } from '../my-programs/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },
    colorTextPrimary: {
      backgroundColor: colors.navBarBackground,
    },
    widget: {
      backgroundColor: colors.lightGray2,
      margin: '20px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        padding: '0px',
      },
    },
    accountBox: {
      margin: '16px 0px 32px',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0px 20px',
      },
    },
    nonRes: {
      display: 'flex',
    },
    nonResText: {
      padding: '20px',
      fontSize: '20px',
      lineHeight: '25px',
      color: colors.lightCyan,
    },
    sectionTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
    },
    sectionSubtext: {
      fontFamily: 'Untitled-Sans',
      marginBottom: '10px',
    },
  }),
);

type Props = {
  path?: string;
};

export default function MyEnergyUse(_: Props) {
  const { accountParams, encryptedAccountNumber } = useSelectedAccountParams();
  const [_2, setEtData] = useState<
    | {
        getAccountDetails: Array<AccountDetail>;
      }
    | undefined
  >();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { accountTypeData } = useGetAccountType({});

  const { data: account } = useAuthQuery<{
    getAccountDetails: [AccountDetail];
  }>(GET_ACCOUNT_DETAILS, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
    errorPolicy: 'all',
  });

  const encryptedServiceAgreementId = getActiveEncryptedSaId(
    account?.getAccountDetails?.[0]?.premiseInfo as PremiseInfo[] | undefined,
  );

  const { loading: eTLoading, data: etData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getPgeEnergyTrackerData, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
  });

  const { data: timeOfDayData } = useAuthQuery<{
    getTimeOfDayEnrollmentDetails: TimeOfDayEnrollmentDetails;
  }>(getTimeOfDayEnrollmentDetailsQuery, {
    variables: {
      params: {
        encryptedServiceAgreementId,
        encryptedAccountNumber,
      },
    },
    skip: !encryptedServiceAgreementId || !encryptedAccountNumber,
  });

  useEffect(() => {
    setEtData?.(etData);
  }, [etData]);

  const pgeEnergyTrackerData = etData?.getAccountDetails?.[0]?.pgeEnergyTracker;
  const isResAccount =
    accountTypeData?.getAccountDetails[0]?.accountType === AccountType?.Res;
  const isLoading = etData === undefined || eTLoading;

  if (isLoading || accountTypeData === undefined) {
    return <Backdrop forceOpen message={t('STILL_LOADING')} />;
  }
  return (
    <Fragment>
      {isLoading || accountTypeData === undefined ? (
        <Backdrop forceOpen message={t('STILL_LOADING')} />
      ) : (
        <>
          <Typography className={classes.heading}>
            {t('MY_ENERGY_USE')}
          </Typography>
          <Box className={classes.accountBox}>
            <AccountDropdown />
          </Box>
          {isResAccount ? (
            <>
              <Typography className={classes.sectionTitle}>
                {t('HOME_ENERGY_ANALYSIS')}
              </Typography>
              <Typography className={classes.sectionSubtext}>
                {t('HOME_ENERGY_SUBTEXT')}
              </Typography>
              <Box className={classes.accountBox}>
                <Card className={classes.colorTextPrimary}>
                  <CardContent className={classes.widget}>
                    <EnergyAnalysisWidget
                      entityId={
                        pgeEnergyTrackerData?.energyTrackerInfo
                          ?.identifiers?.[0]
                      }
                      props={[
                        {
                          'opower-instance': 'widget-survey-splash',
                        },
                      ]}
                    />
                    <DisaggregationWidget
                      entityId={
                        pgeEnergyTrackerData?.energyTrackerInfo
                          ?.identifiers?.[0]
                      }
                      props={[
                        {
                          'opower-instance': 'widget-usage-categories',
                        },
                      ]}
                    />
                  </CardContent>
                </Card>
              </Box>
              <Box>
                <Typography className={classes.sectionTitle}>
                  {t('MY_USE')}
                </Typography>
                {timeOfDayData?.getTimeOfDayEnrollmentDetails?.isEnrolled ? (
                  <Typography className={classes.sectionSubtext}>
                    {richT('MY_USE_TEXT')}
                  </Typography>
                ) : (
                  <Typography className={classes.sectionSubtext}>
                    {t('MY_USE_SUBTEXT')}
                  </Typography>
                )}
                <Card className={classes.colorTextPrimary}>
                  <CardContent className={classes.widget}>
                    <DataBrowserWidget
                      entityId={
                        pgeEnergyTrackerData?.energyTrackerInfo
                          ?.identifiers?.[0]
                      }
                    />
                  </CardContent>
                </Card>
              </Box>
              <Box className={classes.accountBox}>
                <Card className={classes.colorTextPrimary}>
                  <CardContent className={classes.widget}>
                    <NextBestActionWidget
                      entityId={
                        pgeEnergyTrackerData?.energyTrackerInfo
                          ?.identifiers?.[0]
                      }
                    />
                  </CardContent>
                </Card>
              </Box>
            </>
          ) : (
            !isResAccount && (
              <Box>
                <Card className={classes.colorTextPrimary}>
                  <CardContent className={classes.widget}>
                    <DataBrowserWidget
                      entityId={
                        pgeEnergyTrackerData?.energyTrackerInfo
                          ?.identifiers?.[0]
                      }
                      props={[
                        {
                          'opower-instance': 'widget-data-browser-non-res',
                        },
                      ]}
                    />
                  </CardContent>
                </Card>
              </Box>
            )
          )}
        </>
      )}
    </Fragment>
  );
}
