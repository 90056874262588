import React from 'react';
import { SmartBatteryCardType } from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import routes from '../../../../routes';
import { SmartBattery as TSmartBattery } from '../../types';
import { Card } from '../Card';
import { SeasonalDates } from './SeasonalDates';
import { stripOffCents } from '../../../account-summary/energy-usage/current-use-card/useCurrentUseCard';

export function SmartBattery(props: TSmartBattery) {
  const {
    cardType,
    currentBillCreditAmount,
    currentBillKwh: currentBillKwhWithDecimals,
    peakTimeSeason,
    ytdCreditAmount,
    ytdKwh: ytdKwhWithDecimals,
  } = props;
  const currentBillKwh = String(
    stripOffCents(Number(currentBillKwhWithDecimals ?? 0)),
  );
  const ytdKwh = String(stripOffCents(Number(ytdKwhWithDecimals ?? 0)));
  const { t, inlineRichT } = useTranslation();

  if (cardType === SmartBatteryCardType.NewEnrollment) {
    return (
      <Card
        type={'full'}
        theme={'blue'}
        title={t('SMART_BATTERY_CARD_01_TITLE')}
        sectionA={inlineRichT('SMART_BATTERY_CARD_01_SECTION_A')}
        sectionB={<SeasonalDates peakTimeSeason={peakTimeSeason} />}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PGE_SMART_BATTERY}
      />
    );
  }

  if (cardType === SmartBatteryCardType.SavingsOnCurrentBillNoYtd) {
    return (
      <Card
        type={'full'}
        theme={'blue'}
        title={t('SMART_BATTERY_CARD_02_TITLE')}
        sectionA={inlineRichT('SMART_BATTERY_CARD_02_SECTION_A', {
          currentBillCreditAmount,
          currentBillKwh,
        })}
        sectionB={<SeasonalDates peakTimeSeason={peakTimeSeason} />}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PGE_SMART_BATTERY}
      />
    );
  }

  if (cardType === SmartBatteryCardType.SavingsOnCurrentBillAndYtd) {
    return (
      <Card
        type={'full'}
        theme={'blue'}
        title={t('SMART_BATTERY_CARD_03_TITLE')}
        sectionA={inlineRichT('SMART_BATTERY_CARD_03_SECTION_A', {
          currentBillCreditAmount,
          currentBillKwh,
          ytdCreditAmount,
          ytdKwh,
        })}
        sectionB={<SeasonalDates peakTimeSeason={peakTimeSeason} />}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PGE_SMART_BATTERY}
      />
    );
  }

  if (cardType === SmartBatteryCardType.SavingsOnYtdNoCurrentBill) {
    return (
      <Card
        type={'full'}
        theme={'blue'}
        title={t('SMART_BATTERY_CARD_03_TITLE')}
        sectionA={inlineRichT('SMART_BATTERY_CARD_04_SECTION_A', {
          ytdCreditAmount,
          ytdKwh,
        })}
        sectionB={<SeasonalDates peakTimeSeason={peakTimeSeason} />}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PGE_SMART_BATTERY}
      />
    );
  }

  if (cardType === SmartBatteryCardType.NoSavings) {
    return (
      <Card
        type={'full'}
        theme={'blue'}
        title={t('IS_EVERYTHING_OK')}
        sectionA={inlineRichT('SMART_BATTERY_CARD_05_SECTION_A')}
        sectionB={<SeasonalDates peakTimeSeason={peakTimeSeason} />}
        ctaText={t('LEARN_MORE')}
        ctaLink={routes.PGE_SMART_BATTERY}
      />
    );
  }

  return <></>;
}
