import React, { useContext, useEffect, useState } from 'react';
import {
  AccountDetail,
  AccountParams,
  PeakTimeRebateProgramStatus,
  RenewableEnrollment,
  TimeOfDayInfo,
  TimeOfDayEnrollmentStatus,
  NotificationFor,
  SmartThermostatEnrollmentEnrollment,
  AccountType,
  EnergyTrackerData,
  PaymentExtensionEligibility,
  PaymentExtensionResponse,
  ServicePointDetail,
} from '../../../__generated__/pge-types';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';

import ToggleableOptionsList, {
  Option,
  ToggleableOptionsListContent,
} from '../../account-summary/toggleable-options-list/ToggleableOptionsList';
import CurrentBill from '../../account-summary/current-bill';
import ReconnectCard from '../../account-summary/reconnect-card';
import EnergyImpactCarousel from '../../account-summary/energy-impact-carousel/EnergyImpactCarousel';
import EnergyUsage from '../../account-summary/energy-usage';
import Paper from '@material-ui/core/Paper';
import SecureSitePromo from '../../secure-site-promo';
import ManageAlerts from '../../account-summary/manage-alerts/ManageAlerts.section';
import Backdrop from '../../backdrop';
import Drawer from '../../expansion-panel';
import DisconnectNotice from '../../disconnect-notice/DisconnectNotice';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  getAccountDetails,
  getPgeEnergyTrackerData,
  getOnPremDetails,
  getSmartThermostatEnrollmentStatusQuery,
  getEnergyTrackerData,
  getAccountDetailsForOutages,
} from './queries';
import ROUTES from '../../../routes';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { dateSuffixMap, toSentenceCase } from '../../../util/format';
import SpinnerCard from '../../spinner/SpinnerCard';
import ErrorOverlay from '../../error/error-overlay';
import ColorBlendSpinner from '../../spinner/ColorBlendSpinner';
import { navigate, useLocation } from '@reach/router';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import NoServiceAgreement from '../no-service-agreement';
import BroadcastMessage from '../broadcast-message/BroadcastMessage';
import { isTimeOfDayEnrolled } from '../../time-of-day/util';
import useTPA from '../../need-more-time-to-pay/tpa/useTPA';
import useContentMgmt from '../../../hooks/useContentMgmt';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { useOutageLookUp } from '../../outage/outage-center/hooks/useOutageLookUp';
import ResCurrentBill from '../res-current-bill';
import { CurrentUseCard } from '../energy-usage/current-use-card';
import usePgeQuery from '../../../hooks/usePgeQuery';
import { useAccountCustomerDashboard } from '../../../hooks/useAccountCustomerDashboard';
import { SSMDashboardCard } from '../../ssm-dashboard-card';
import { getAccountDetailsForPaymentExtension } from '../../../queries/paymentExtension.query';
import { useApolloClient } from '@apollo/react-hooks';
import { AccountDashboardIntercept } from '../../account-dashboard-intercept';
import { NextBestActionWidget } from '../../OpowerWidgets';
import { DashboardStaticCard } from '../../dashboard-static-card';
import BillComparisonImg from '../../../static/bill-comparison.svg';
import MyEnergyUseImg from '../../../static/my-energy-use-static.svg';
import { OutageCard } from '../outage-card';
import { ProgramsPanel } from '../../programs-panel';
import { Box, CircularProgress } from '@material-ui/core';
import { NextBestOffer } from '../../next-best-offer';
import PendingApplicationBanner from '../../pge-plus-form/PendingApplicationBanner';
import AlertBanner from '../../AlertBanner';

type Props = {
  accountParams: AccountParams | undefined;
  encryptedAccountNumber: string | null | undefined;
  encryptedPersonId: string | undefined;
  setIsDisonnected: (value: boolean) => void;
  setIsEligibleForReconnect: (value: boolean) => void;
  setIsReconnectInProgress: (value: boolean) => void;
} & WithWidth;

const getBillingAndPaymentOptions = (
  selectedAccount: AccountDetail,
  t: (translation: string) => string,
): Option[] => {
  const paymentOptions: Option[] = [];
  if (selectedAccount) {
    const {
      isPaperlessBillEnrolled,
      preferredDueDate,
      equalpay,
      accountType,
    } = selectedAccount;
    const autoPay: Option = {
      name: t('AUTO_PAY'),
      active: Boolean(selectedAccount?.autoPay?.isEnrolled),
      link: ROUTES.AUTO_PAY,
      subText:
        accountType === AccountType.Res
          ? t('AUTO_PAY_PROGRAM_SUBTEXT')
          : undefined,
    };
    const paperlessBilling: Option = {
      name: t('PAPERLESS_BILL'),
      active: isPaperlessBillEnrolled?.result ?? false,
      link: ROUTES.PAPERLESS_BILLING,
      subText:
        accountType === AccountType.Res
          ? t('PAPERLESS_PROGRAM_SUBTEXT')
          : undefined,
    };
    const preferredDueDateActive = Boolean(
      preferredDueDate?.dueDate?.preferredDueDate,
    );
    const preferredDueDateOption: Option = {
      name: preferredDueDateActive
        ? `${t('PREFERRED_DUE_DATE')}${preferredDueDateActive ? ': ' : ''}`
        : t('PDD_PAGE_TITLE'),
      active: preferredDueDateActive,
      data: {
        value: preferredDueDateActive
          ? `${preferredDueDate?.dueDate?.preferredDueDate}${dateSuffixMap(
              t,
              preferredDueDate?.dueDate?.preferredDueDate,
            )}`
          : '',
      },
      link: ROUTES.PREFERRED_DUE_DAY,
      subText:
        accountType === AccountType.Res
          ? t('DUEDATE_PROGRAM_SUBTEXT')
          : undefined,
    };
    const paymentType: Option = {
      name: t('EQUAL_PAY_TITLE'),
      active: equalpay?.paymentPlanType! === 'EqualPay',
      disabled: false,
      link: ROUTES.EQUAL_PAY,
      subText:
        accountType === AccountType.Res
          ? t('EQUAL_PAY_PROGRAM_SUBTEXT')
          : undefined,
    };
    paymentOptions.push(
      autoPay,
      paymentType,
      paperlessBilling,
      preferredDueDateOption,
    );
  }

  return [...paymentOptions];
};

const getEnrollments = (
  peaktimeRebate: PeakTimeRebateProgramStatus | null | undefined,
  renewableEnrollment: RenewableEnrollment | null | undefined,
  timeofdayEnrollment: TimeOfDayInfo | null | undefined,
  smartThermostatEnrollmentStatus:
    | SmartThermostatEnrollmentEnrollment
    | null
    | undefined,
  t: (translation: string) => string,
): Option[] => {
  const enrollmentOptions: Option[] = [];
  const peakTimeRebatesStatus =
    peaktimeRebate?.peakTimeRebateEnrollmentStatus || '';
  const peakTimeRebates: Option = {
    name: t('PTR_SENTENCE_CASE'),
    active: ['Changed', 'Enrolled'].includes(peakTimeRebatesStatus),
    disabled: false,
    link: ROUTES.PEAKTIME_REBATES,
  };

  const renewablePowerEnrolledStatus =
    renewableEnrollment?.renewableEnrollmentStatus;
  const renewablePower: Option = {
    name: toSentenceCase(t('RENEWABLE_POWER')),
    active: renewablePowerEnrolledStatus === 'Enrolled',
    disabled: false,
    link: ROUTES.RENEWABLE_POWER,
  };

  const smartThermostatEnrollment =
    smartThermostatEnrollmentStatus?.smartThermostatEnrollmentStatus;
  const smartThermostat: Option = {
    name: toSentenceCase(t('SMART_THERMOSTAT')),
    active: smartThermostatEnrollment === 'Enrolled',
    disabled: false,
    link: ROUTES.PGE_MARKETPLACE_SMART_THERMOSTAT,
  };

  const timeofdayEnrolledStatus = timeofdayEnrollment?.enrollmentStatus;
  const timeofday: Option | undefined = timeofdayEnrollment
    ? {
        name:
          timeofdayEnrolledStatus === TimeOfDayEnrollmentStatus.PendingEnrolled
            ? t('TOD_PROCESSING')
            : t('TOD_TITLE_CASE'),
        active: isTimeOfDayEnrolled(timeofdayEnrolledStatus),
        disabled: false,
        link: ROUTES.TIMEOFDAY,
        pending:
          timeofdayEnrolledStatus === TimeOfDayEnrollmentStatus.PendingEnrolled,
      }
    : undefined;

  enrollmentOptions.push(peakTimeRebates, renewablePower);
  return [
    ...enrollmentOptions,
    ...(timeofday ? [timeofday] : []),
    smartThermostat,
  ];
};

function SingleAccountSummary({
  accountParams,
  encryptedAccountNumber,
  encryptedPersonId,
  width,
  setIsDisonnected,
  setIsEligibleForReconnect,
  setIsReconnectInProgress,
}: Props) {
  const { showLegacyUI } = useAccountCustomerDashboard();
  const { customer } = useAccountCustomer();
  const isMobile = ['xs', 'sm'].includes(width);
  const [showDisconnectNotice, toggleDisconnectNotice] = useState<boolean>(
    false,
  );
  const [
    outageDetails,
    setOutageDetails,
  ] = useState<ServicePointDetail | null>();
  const {
    isPGEPlusEVEnabled,
    isSecureSiteModalEnabled,
    isShowAccountSummaryMessage,
  } = useContext(featureFlagsContext);
  const location = useLocation();
  const { wrapWithLoader } = useWrapWithLoader();
  const { getOutageLookUpQueryResponse } = useOutageLookUp();

  const { t, richT } = useTranslation();
  const { tpaDetails } = useTPA({ shouldRedirect: false });
  const [paymentExtentionData, setPaymentExtentionData] = useState<
    PaymentExtensionResponse | undefined
  >();
  const { loading: hookLoading, data } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const apolloClient = useApolloClient();
  const getOutageDetails = wrapWithLoader(async () => {
    const result = await apolloClient.query<{
      getAccountDetails: Array<AccountDetail>;
    }>({
      query: getAccountDetailsForOutages,
      variables: {
        params: {
          accountNumberList: [accountParams],
        },
      },
      fetchPolicy: 'network-only',
    });
    const outageReportedPremiseInfo = result?.data?.getAccountDetails[0].premiseInfo?.find(
      premiseInfo => {
        return premiseInfo?.servicePointDetails
          ? premiseInfo?.servicePointDetails?.filter(point => {
              return point?.outageInfo?.hasKnownOutage;
            }).length > 0
          : [];
      },
    );
    setOutageDetails(
      outageReportedPremiseInfo?.servicePointDetails?.find(
        outage =>
          outage?.outageInfo?.hasKnownOutage && outage?.meter?.status === 'off',
      ),
    );
  });

  useEffect(() => {
    const fetchPaymentExtensionDetails = async () => {
      const result = await apolloClient.query<{
        getAccountDetails: Array<AccountDetail>;
      }>({
        query: getAccountDetailsForPaymentExtension,
        variables: {
          params: {
            accountNumberList: [accountParams],
          },
        },
        fetchPolicy: 'network-only',
      });
      const paymentExtensionInfo =
        result?.data?.getAccountDetails?.[0]?.paymentExtensionInfo;
      setPaymentExtentionData(paymentExtensionInfo!);
    };
    !showLegacyUI && void getOutageDetails();
    !showLegacyUI && void fetchPaymentExtensionDetails();
  }, [showLegacyUI]);

  useEffect(() => {
    location.hash !== '#energyUse' && window.scrollTo(0, 0);
  }, []);

  const loading = hookLoading || !data || !accountParams;
  const account = data?.getAccountDetails?.[0];
  const isDisconnected = Boolean(account?.serviceConnectivity?.isDisconnected);
  const isEligibleForReconnect = Boolean(
    account?.serviceConnectivity?.isEligibleForReconnect,
  );
  const isReconnectInProgress = Boolean(
    account?.serviceConnectivity?.isReconnectInProgress,
  );
  const { data: onPremData, loading: onPremLoading } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getOnPremDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
      commPreferencesParams: {
        notificationFor: NotificationFor.Alert,
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const onPremDetails = onPremData?.getAccountDetails?.[0];
  const peaktimeRebate = onPremDetails?.peaktimeRebate;
  const renewableEnrollment = onPremDetails?.renewableEnrollment;
  const timeofdayEnrollment = onPremDetails?.timeOfDayInfo;
  const outageCause =
    onPremData?.getAccountDetails?.[0].premiseInfo?.[0]
      ?.servicePointDetails?.[0]?.outageInfo?.cause;

  const { loading: eTLoading, data: etData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getPgeEnergyTrackerData, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },

    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
    onError: () => {},
    skip: !accountParams,
  });

  const { isOutageMode } = useContext(featureFlagsContext);

  const {
    loading: pgeETWidgetLoading,
    data: pgeETWidgetData,
    error: pgeETWidgetError,
  } = usePgeQuery<{
    getEnergyTrackerData: EnergyTrackerData;
  }>(getEnergyTrackerData, {
    variables: {
      params: {
        encryptedAccountNumber,
        encryptedPersonId,
      },
    },
    skip: isOutageMode || account?.accountType !== AccountType.Res,
    errorPolicy: 'all',
  });

  const { data: stData } = useAuthQuery<{
    getSmartThermostatEnrollmentStatus: SmartThermostatEnrollmentEnrollment;
  }>(getSmartThermostatEnrollmentStatusQuery, {
    variables: {
      payload: {
        encryptedAccountNumber,
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
    onError: () => {},
    skip: !accountParams,
  });

  const smartThermostatEnrollment = stData?.getSmartThermostatEnrollmentStatus;
  const energyTrackerLoading = eTLoading || !etData;
  const pgeEnergyTrackerData = etData?.getAccountDetails?.[0]?.pgeEnergyTracker;

  const showEnergyTrackerWidget =
    pgeEnergyTrackerData?.billableAccountDetails?.isAccountBillable &&
    pgeEnergyTrackerData?.billableAccountDetails
      ?.isServiceDesignationElectric &&
    pgeEnergyTrackerData?.energyTrackerInfo?.showEnergyTracker;
  const energyTrackerDataProps = {
    pgeEnergyTrackerData: pgeEnergyTrackerData,
    encryptedAccountNumber: encryptedAccountNumber,
    encryptedPersonId: encryptedPersonId,
  };
  const microcopyGroupEntryId = '4XvcaAApKXnncaRnTaX7o5';
  const { content } = useContentMgmt(microcopyGroupEntryId);
  useEffect(() => {
    location.hash === '#energyUse' &&
      document.getElementById('energyUse')?.scrollIntoView();
  }, [etData?.getAccountDetails?.[0]?.pgeEnergyTracker]);

  useEffect(() => {
    if (
      account?.pendingDisconnect?.isPendingDisconnect &&
      showDisconnectNotice === false
    ) {
      // FIXME: Having this run immediately, without the setTimeout
      // causes an issue with the EnergyImpactCarousel, specifically
      // the Slider component it is built on.
      setTimeout(() => toggleDisconnectNotice(true));
    }
  }, [account?.pendingDisconnect?.isPendingDisconnect]);

  useEffect(() => {
    setTimeout(() =>
      setIsDisonnected(account?.serviceConnectivity?.isDisconnected!),
    );

    setTimeout(() =>
      setIsEligibleForReconnect(
        account?.serviceConnectivity?.isEligibleForReconnect!,
      ),
    );
    setTimeout(() =>
      setIsReconnectInProgress(
        account?.serviceConnectivity?.isReconnectInProgress!,
      ),
    );
  }, [account?.serviceConnectivity, account?.accountNumber]);

  useEffect(() => {
    // If we aren't loading, but we dont have an account, there was
    // an error pulling the requested account, so lets forward to the Error
    // page
    if (!loading && !account && accountParams) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.ERROR);
    }
  }, [data, account, loading, accountParams]);

  if (customer?.accountMeta?.totalAccounts === 0) {
    return (
      <Grid item xs={12}>
        <NoServiceAgreement />
      </Grid>
    );
  }

  if (!account || loading) {
    return <Backdrop forceOpen message={t('STILL_LOADING')} />;
  }

  if ((customer?.accountMeta?.totalAccounts ?? 0) > 0) {
    return (
      <>
        <BroadcastMessage />

        {!isDisconnected &&
          showDisconnectNotice &&
          account?.pendingDisconnect?.isPendingDisconnect &&
          account?.billInfo?.amountDue! > 0 && (
            <Grid item>
              <DisconnectNotice
                dismissDisconnectNotice={() => toggleDisconnectNotice(false)}
              />
            </Grid>
          )}
        {!showLegacyUI && outageDetails && outageCause !== 'PSPS' && (
          <Grid item>
            <OutageCard
              outageDetails={outageDetails}
              accountParams={accountParams}
              customerContact={
                customer && customer?.contactDetails && customer?.contactDetails
              }
            />
          </Grid>
        )}

        {!isReconnectInProgress && isDisconnected ? (
          <>
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="stretch">
                <Grid item xs={12} md={7}>
                  <Card>
                    <CardContent>
                      <ReconnectCard
                        isEligibleForReconnect={isEligibleForReconnect}
                        billInfo={account?.billInfo!}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Hidden smDown>
                  <Grid item sm={12} md={5}>
                    <Card>
                      <CardContent>
                        {account?.paymentEligibility ||
                        account?.isPaperlessBillEnrolled ||
                        account?.preferredDueDate?.dueDate ||
                        account?.equalpay ? (
                          <ToggleableOptionsList
                            listTitle={t('BILLING_AND_PAYMENT_SETTINGS')}
                            options={getBillingAndPaymentOptions(account, t)}
                            renderInCard={false}
                            isReconnect={isDisconnected}
                          />
                        ) : (
                          <ErrorOverlay
                            title={t('BILLING_AND_PAYMENT_SETTINGS')}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              {isShowAccountSummaryMessage && (
                <AlertBanner
                  hide={account.accountType !== AccountType.Res}
                  title={t('2025_PRICE_INCREASE_TITLE')}
                  text={t('2025_PRICE_INCREASE_TEXT')}
                  learnMoreLink={t('2025_PRICE_INCREASE_LEARN_MORE_LINK')}
                />
              )}
              {isPGEPlusEVEnabled && <PendingApplicationBanner />}
              {showLegacyUI ? (
                <CurrentBill
                  billInfo={account?.billInfo || null}
                  autoPay={account?.autoPay || null}
                  paymentPlanType={account?.equalpay?.paymentPlanType || null}
                  isPendingDisconnect={
                    account.pendingDisconnect?.isPendingDisconnect
                  }
                  billDetails={account?.billInfo?.billDetails || null}
                  tpaDetails={tpaDetails}
                  isSummaryBillAccount={account?.isSummaryBillAccount!}
                />
              ) : (
                <>
                  <ResCurrentBill
                    billInfo={account?.billInfo || null}
                    autoPay={account?.autoPay || null}
                    isNewAccount={account?.billInfo?.isNewAccount}
                    paymentPlanType={account?.equalpay?.paymentPlanType || null}
                    isPendingDisconnect={
                      account.pendingDisconnect?.isPendingDisconnect
                    }
                    billDetails={account?.billInfo?.billDetails || null}
                    isSummaryBillAccount={account?.isSummaryBillAccount!}
                    optionList={getBillingAndPaymentOptions(account, t)}
                    isTpaEligible={
                      tpaDetails
                        ? tpaDetails?.isEnrolled ||
                          tpaDetails?.isEligible ||
                          (paymentExtentionData?.eligibility &&
                            paymentExtentionData?.eligibility !==
                              PaymentExtensionEligibility.Ineligible) ||
                          paymentExtentionData?.isEnrolled!
                        : false
                    }
                  />
                  {!Boolean(account?.billInfo?.billDetails?.amountDue) && (
                    <div style={{ marginTop: '1.5rem' }}>
                      <ManageAlerts />
                    </div>
                  )}
                </>
              )}
            </Grid>
            {showLegacyUI && (
              <Grid item sm={12}>
                <Card>
                  <CardContent style={{ paddingBottom: '16px' }}>
                    {account?.paymentEligibility ||
                    account?.isPaperlessBillEnrolled ||
                    account?.preferredDueDate?.dueDate ||
                    account?.equalpay ? (
                      <ToggleableOptionsList
                        listTitle=""
                        options={getBillingAndPaymentOptions(account, t)}
                        renderInCard={false}
                        isMobile={isMobile}
                      />
                    ) : (
                      <ErrorOverlay title={t('BILLING_AND_PAYMENT_SETTINGS')} />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          </>
        )}
        {showLegacyUI &&
          showEnergyTrackerWidget &&
          (energyTrackerLoading || loading ? (
            <Grid item xs={12}>
              <SpinnerCard />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <a id="energyUse" />
              {energyTrackerDataProps?.pgeEnergyTrackerData ? (
                <EnergyUsage {...energyTrackerDataProps} />
              ) : (
                <Paper>
                  {' '}
                  <CardContent>
                    {' '}
                    <ErrorOverlay
                      title={toSentenceCase(t('TRACK_YOUR_ENERGY_USE'))}
                    />{' '}
                  </CardContent>{' '}
                </Paper>
              )}
            </Grid>
          ))}
        {!showLegacyUI && (
          <>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {eTLoading || pgeETWidgetLoading ? (
                    <Paper>
                      <CardContent
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress />
                      </CardContent>
                    </Paper>
                  ) : Boolean(pgeETWidgetError) ||
                    (etData?.getAccountDetails?.[0]?.pgeEnergyTracker
                      ?.energyTrackerInfo?.showEnergyTracker &&
                      pgeETWidgetData?.getEnergyTrackerData
                        ?.detailsAvailable) ? (
                    <CurrentUseCard
                      loading={pgeETWidgetLoading}
                      data={pgeETWidgetData}
                      error={Boolean(pgeETWidgetError)}
                      accountParams={accountParams}
                    />
                  ) : (
                    <DashboardStaticCard
                      title={t('BILL_COMPARISON_TITLE')}
                      subText={t('BILL_COMPARISON_SUBTEXT')}
                      footerText={t('VIEW_BILL_DETAILS')}
                      image={<BillComparisonImg />}
                      path={`${ROUTES.VIEW_BILL}#energyUse`}
                    />
                  )}
                  <Box style={{ marginTop: '1rem' }}>
                    <DashboardStaticCard
                      title={t('MY_ENERGY_USE')}
                      subText={t('FIND_OUT_WHAT_IS_COSTING_YOU')}
                      footerText={t('ENERGY_USE_DETAILS')}
                      image={<MyEnergyUseImg />}
                      path={ROUTES.MY_ENERGY_USE}
                    />
                  </Box>
                </Grid>
                {!isMobile && (
                  <Grid item xs={12} sm={6}>
                    <ProgramsPanel />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isMobile && (
              <Grid item>
                <ProgramsPanel />
              </Grid>
            )}
            {energyTrackerDataProps?.pgeEnergyTrackerData && (
              <Grid item xs={12} sm={12}>
                <Card>
                  <CardContent>
                    <NextBestActionWidget
                      entityId={
                        pgeEnergyTrackerData?.energyTrackerInfo
                          ?.identifiers?.[0]
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
            {!showLegacyUI && (
              <Grid item>
                <NextBestOffer />
              </Grid>
            )}
            <Grid item>
              <SSMDashboardCard />
            </Grid>
          </>
        )}
        {/* hiding marketing cards for now */}
        {false && (
          <Grid item xs={12}>
            <EnergyImpactCarousel isMobile={Boolean(isMobile)} />
          </Grid>
        )}

        <Grid item>
          {isMobile ? (
            <>
              <Grid item xs={12} style={{ marginTop: '2em' }}>
                {Boolean(account?.billInfo?.billDetails?.amountDue) && (
                  <ManageAlerts />
                )}
              </Grid>
              {showLegacyUI && (
                <Grid item xs={12} lg={8} style={{ marginTop: '2em' }}>
                  <Drawer
                    title={t('MANAGE_ENROLLMENTS')}
                    style={{ marginTop: '-2em' }}
                  >
                    {onPremLoading ? (
                      <ColorBlendSpinner />
                    ) : peaktimeRebate ||
                      renewableEnrollment ||
                      timeofdayEnrollment ||
                      smartThermostatEnrollment ? (
                      <ToggleableOptionsListContent
                        isMobile={isMobile}
                        options={getEnrollments(
                          peaktimeRebate,
                          renewableEnrollment,
                          timeofdayEnrollment,
                          smartThermostatEnrollment,
                          t,
                        )}
                      />
                    ) : (
                      <CardContent>
                        {' '}
                        <ErrorOverlay
                          title={t('MANAGE_ENROLLMENTS')}
                          hide
                        />{' '}
                      </CardContent>
                    )}
                  </Drawer>
                </Grid>
              )}
              <Hidden smDown>
                <Grid item xs={12} style={{ marginTop: '2em' }}>
                  <Drawer
                    title={t('BILLING_AND_PAYMENT_SETTINGS')}
                    style={{ marginTop: '-2em' }}
                  >
                    {account?.paymentEligibility ||
                    account?.isPaperlessBillEnrolled ||
                    account?.preferredDueDate?.dueDate ||
                    account?.equalpay ? (
                      <ToggleableOptionsListContent
                        isMobile={isMobile}
                        options={getBillingAndPaymentOptions(account, t)}
                      />
                    ) : (
                      <CardContent>
                        {' '}
                        <ErrorOverlay
                          title={t('BILLING_AND_PAYMENT_SETTINGS')}
                          hide
                        />{' '}
                      </CardContent>
                    )}
                  </Drawer>
                </Grid>
              </Hidden>
            </>
          ) : (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={showLegacyUI ? 7 : 12}>
                {Boolean(account?.billInfo?.billDetails?.amountDue) && (
                  <ManageAlerts />
                )}
              </Grid>
              <Grid item xs={showLegacyUI ? 5 : 6}>
                {showLegacyUI && (
                  <Card>
                    <CardContent>
                      <Typography variant={'h2'}>
                        {toSentenceCase(t('PROGRAM_ENROLLMENTS'))}
                      </Typography>
                      {onPremLoading ? (
                        <ColorBlendSpinner />
                      ) : peaktimeRebate ||
                        renewableEnrollment ||
                        timeofdayEnrollment ||
                        smartThermostatEnrollment ? (
                        <ToggleableOptionsListContent
                          options={getEnrollments(
                            peaktimeRebate,
                            renewableEnrollment,
                            timeofdayEnrollment,
                            smartThermostatEnrollment,
                            t,
                          )}
                          isMobile={true}
                        />
                      ) : (
                        <CardContent>
                          {' '}
                          <ErrorOverlay
                            title={t('PROGRAM_ENROLLMENTS')}
                            hide
                          />{' '}
                        </CardContent>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        {showLegacyUI && (
          <Grid item>
            <SecureSitePromo />
          </Grid>
        )}
        {isSecureSiteModalEnabled && <AccountDashboardIntercept />}
      </>
    );
  }

  /**
   * This is for resolving a linting error.
   * The code flow expects at least one return statement.
   * Linter isn't smart enough to map the paths covered above.
   */
  return <></>;
}

export default withWidth()(SingleAccountSummary);
