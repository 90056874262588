import {
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Card,
  CardContent,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { useIsMobile } from '../../util/style-utils';
import PGEButton from '../buttons/PGE-Button';
import { WizardFormProps } from '../utility/wizard/wizard.types';
import Link from '../text-link';
import ROUTES from '../../routes';
import dayjs from 'dayjs';
import clsx from 'clsx';
import AccountGroupDropdown from '../account-group-dropdown';
import { ElectionWindowModel } from '../../hooks/useElectionWindow';
import { navigate } from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnwidth: {
      minWidth: '130px',
    },
    margin0: {
      '& p': {
        margin: theme.spacing(0),
      },
    },
    inlineText: {
      '& p': {
        display: 'inline',
      },
    },
  }),
);

export const ElectionWindowAccountSelection = ({
  formState,
  componentProps,
}: WizardFormProps<ElectionWindowModel>) => {
  const { t, richT } = useTranslation();
  const { electionWindowStatus, eligibleForEnrollment } = componentProps;
  const isMobile = useIsMobile();
  const classes = useStyles();

  const [isElectionWindowClosed, setIsElectionWindowClosed] = useState(false);
  const [periodEndDate, setPeriodEndDate] = useState(new Date());
  const [effectiveDate, setEffectiveDate] = useState(new Date());

  useEffect(() => {
    if (electionWindowStatus?.electionWindow?.toUpperCase() === 'CLOSED') {
      setIsElectionWindowClosed(true);
    } else {
      setPeriodEndDate(electionWindowStatus?.periodEndDate);
      setEffectiveDate(electionWindowStatus?.periodEffectiveDate);
    }
  }, [electionWindowStatus]);

  return (
    <>
      {isElectionWindowClosed ? (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card>
              <CardContent>
                <Grid item>
                  <Grid item xs={12}>
                    <Typography
                      variant={'body1'}
                      style={{ marginBottom: '1em' }}
                      data-testid="window-closed-text"
                    >
                      {t('OPEN_ENROLLMENT_ELECTION_CLOSED_SUBTITLE')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'}>
                      {t('OPEN_ENROLLMENT_NEXT_ENROLLMENT_PERIOD_TEXT1')}
                      <a
                        href={ROUTES.ABOUT_US_MARKET_BASED_PRICING}
                        target="_blank"
                      >
                        {t('OPEN_ENROLLMENT_MARKET_BASED_PRICING_LINK')}
                      </a>{' '}
                      {t('OPEN_ENROLLMENT_NEXT_ENROLLMENT_PERIOD_TEXT2')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify={isMobile ? 'center' : 'flex-end'}
            spacing={1}
          >
            <Grid item xs={10} md="auto">
              <PGEButton className={classes.btnwidth} fullWidth onClick={() => navigate(ROUTES.ACCOUNT)}>
                {t('OK')}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Card>
                <CardContent>
                  <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                      <Typography variant={'body1'} className={classes.margin0}>
                        {richT('OPEN_ENROLLMENT_ELECTION_OPEN_NOTE', {
                          EFFECTIVE_DATE: dayjs(effectiveDate).format('MMMM D'),
                        })}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'h2'}>
                        {t('OPEN_ENROLLMENT_ELECTION_OPEN_SUBTITLE')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction={'column'}>
                        <Grid item>
                          <Typography
                            variant={'body1'}
                            className={classes.margin0}
                          >
                            {richT('OPEN_ENROLLMENT_ELECTION_OPEN_TEXT', {
                              DATE_TO_COMPLETE: dayjs(periodEndDate).set('hour', 17).set('minute', 0).set('second', 0).format(
                                'hh:mm a, MMM. D',
                              ),
                            })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <ul>
                            <li>
                              <Typography
                                variant={'body1'}
                                className={clsx(
                                  classes.margin0,
                                  classes.inlineText,
                                )}
                              >
                                {richT(
                                  'OPEN_ENROLLMENT_ELECTION_OPEN_OPTION1',
                                  {
                                    EFFECTIVE_DATE: dayjs(effectiveDate).format(
                                      'MMMM D',
                                    ),
                                  },
                                )}
                                <span>
                                  <a
                                    target={'__blank'}
                                    href={ROUTES.ESS_DIRECT_ACCESS_OPERATIONS}
                                  >
                                    ESS
                                  </a>
                                  .
                                </span>
                              </Typography>
                            </li>
                            {electionWindowStatus?.electionWindow?.toUpperCase() ===
                              'FEB' && (
                              <li>
                                <Typography
                                  variant={'body1'}
                                  data-testid="window-open-text"
                                >
                                  {t('OPEN_ENROLLMENT_ELECTION_OPEN_OPTION2')}
                                </Typography>
                              </li>
                            )}
                            {electionWindowStatus?.electionWindow?.toUpperCase() ===
                              'NOV' && (
                              <li>
                                <Typography variant={'body1'} data-testid={'nov-window-text'}>
                                  {richT(
                                    'OPEN_ENROLLMENT_ELECTION_OPEN_OPTION3',
                                    {
                                      CHANGE_YEAR: dayjs(effectiveDate).format(
                                        'YYYY',
                                      ),
                                    },
                                  )}
                                </Typography>
                              </li>
                            )}
                          </ul>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={'body1'}
                            style={{ marginBottom: '1em', color: 'red' }}
                          >
                            {t('OPEN_ENROLLMENT_ELECTION_OPEN_INELIGIBLE')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant={'body1'} data-testid={'market-based-pricing-faq-link'}>
                            {t('OPEN_ENROLLMENT_ELECTION_OPEN_READ_FAQ')}
                            <a
                              href={ROUTES.ESS_MARKET_BASED_PRICING_FAQ}
                              target={'__blank'}
                            >
                              FAQ
                            </a>
                            .
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography variant={'h2'}>
                        {t(
                          'OPEN_ENROLLMENT_ELECTION_OPEN_SELECT_ACCOUNT_TITLE',
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <AccountGroupDropdown data-testid="select-account-group" />
                      {!eligibleForEnrollment && (
                        <FormHelperText
                          className={clsx(
                            'MuiFormHelperText-root Mui-error',
                          )}
                          data-testid={'no-service-address-error'}
                        >
                          {t(
                            'OPEN_ENROLLMENT_ELECTION_OPEN_NO_SERVICE_ADDR_ERROR',
                          )}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant={'body1'}>
                        {t('OPEN_ENROLLMENT_ELECTION_OPEN_SELECT_ACCOUNT_NOTE')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link plain to={ROUTES.MANAGE_ACCOUNT_GROUPS}>
                        <Typography>
                          {t(
                            'OPEN_ENROLLMENT_ELECTION_OPEN_CREATE_ACCOUNT_TITLE',
                          )}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
